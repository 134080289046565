import { api } from '@studio/lib';
import {
  BasicOrganization,
  BillingPortalConfiguration,
  BillingPortalConfigurationList,
  BillingSession,
  Subscription,
  SubscriptionResponse,
} from '../routes/types';

export const getOrganization = async (
  userOrgId: string
): Promise<BasicOrganization> => {
  const response: BasicOrganization = await api.bowser.get(
    `/api/organizations/${userOrgId}`
  );
  return response;
};

export const updateOrganization = async (
  name: string,
  userOrgId: string
): Promise<BasicOrganization> => {
  const response: BasicOrganization = await api.bowser.put(
    `/api/organizations/${userOrgId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      data: { name },
    }
  );
  return response;
};

export const getSubscription = async (
  userOrgId: string
): Promise<Subscription> => {
  const response: SubscriptionResponse = await api.bowser.get(
    `/api/subscriptions/${userOrgId}`
  );
  return response.data;
};

export const getBillingSession = async (
  customerId: string
): Promise<BillingSession> => {
  const response: BillingSession = await api.bowser.post(
    `/api/stripe/billing-session`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        configurationId: import.meta.env.VITE_STRIPE_BILLING_CONFIG_ID,
        customerId,
        returnUrl: `${window.location.origin}`,
      },
    }
  );
  return response;
};

export const getSubscriptionConfiguration =
  async (): Promise<BillingPortalConfigurationList> => {
    const response: BillingPortalConfigurationList = await api.bowser.get(
      `/api/stripe/billing-configuration/`
    );
    return response;
  };

export const getStripeCustomer = async (
  userEmail: string
): Promise<BillingPortalConfiguration[]> => {
  const response: BillingPortalConfiguration[] = await api.bowser.get(
    `/api/stripe/customer?email=${userEmail}`
  );
  return response;
};
