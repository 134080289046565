export const PROJECTS_FILTER_ACCESS_CHANGE = 'projects_filter_access_change';
export const PROJECTS_FILTER_ACCESS_OPEN = 'projects_filter_access_open';
export const PROJECTS_FILTER_OUTLIER_CHANGE = 'projects_filter_outlier_change';
export const PROJECTS_FILTER_OUTLIER_OPEN = 'projects_filter_outlier_open';
export const PROJECTS_FILTER_VIDEO_DURATION_CHANGE = 'projects_filter_videoduration_change';
export const PROJECTS_FILTER_VIDEO_VIEWS_CHANGE = 'projects_filter_videoviews_change';
export const PROJECTS_ADD_NEW_CLICK_ON_SIDE = 'projects_add_new_click_on_side';
export const PROJECTS_AI_SETTINGS_CLICK = 'projects_AI_settings_click';
export const PROJECTS_BRAINSTORM_BACK_TO_PROJECT_CLICK = 'projects_brainstorm_back_to_project_click';
export const PROJECTS_BRAINSTORM_BACKUPS_CLICK = 'projects_brainstorm_backups_click';
export const PROJECTS_BRAINSTORM_BACKUPS_ELEMENT_CLICK = 'projects_brainstorm_backups_element_click';
export const PROJECTS_BRAINSTORM_CHANGE_CLICK = 'projects_brainstorm_change_click';
export const PROJECTS_BRAINSTORM_CLICK = 'projects_brainstorm_click';
export const PROJECTS_BRAINSTORM_CONTINUE_CLICK = 'projects_brainstorm_continue_click';
export const PROJECTS_BRAINSTORM_COPY_CLICK = 'projects_brainstorm_copy_click';
export const PROJECTS_BRAINSTORM_EDIT_TEXTBOX_CHANGE = 'projects_brainstorm_edit_textbox_change';
export const PROJECTS_BRAINSTORM_KEEP_CLICK = 'projects_brainstorm_keep_click';
export const PROJECTS_BRAINSTORM_MORE_CLICK = 'projects_brainstorm_more_click';
export const PROJECTS_BRAINSTORM_NEXT_PAGE_CLICK = 'projects_brainstorm_next_page_click';
export const PROJECTS_BRAINSTORM_PREVIOUS_PAGE_CLICK = 'projects_brainstorm_previous_page_click';
export const PROJECTS_BRAINSTORM_POWERKEYWORD_HOVER = 'projects_brainstorm_powerkeyword_hover';
export const PROJECTS_BRAINSTORM_POWERKEYWORD_OUTLIER_LINK_CLICK = 'projects_brainstorm_powerkeyword_outlier_link_click';
export const PROJECTS_BRAINSTORM_SETTING_ADDITIONAL_DETAIL_CHANGE = 'projects_brainstorm_setting_additional_detail_change';
export const PROJECTS_BRAINSTORM_SETTING_AUDIENCE_DESCRIPTION_CHANGE = 'projects_brainstorm_setting_audience_description_change';
export const PROJECTS_BRAINSTORM_SETTING_CLICK = 'projects_brainstorm_setting_click';
export const PROJECTS_BRAINSTORM_SETTING_GENERATE_CLICK = 'projects_brainstorm_setting_generate_click';
export const PROJECTS_BRAINSTORM_SETTING_INPUT_CHANGE = 'projects_brainstorm_setting_input_change';
export const PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_CONCEPT_CHANGE = 'projects_brainstorm_setting_input_current_options_concept_change';
export const PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_THUMBNAIL_DELETE_CLICK = 'projects_brainstorm_setting_input_current_options_thumbnail_delete_click';
export const PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_THUMBNAIL_DOWNLOAD_CLICK = 'projects_brainstorm_setting_input_current_options_thumbnail_download_click';
export const PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_THUMBNAIL_UPLOAD_CLICK = 'projects_brainstorm_setting_input_current_options_thumbnail_upload_click';
export const PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_THUMBNAIL_UPLOAD_SUCCESS = 'projects_brainstorm_setting_input_current_options_thumbnail_upload_success';
export const PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_TITLE_CHANGE = 'projects_brainstorm_setting_input_current_options_title_change';
export const PROJECTS_BRAINSTORM_SETTING_INSPIRED_BY_CHANGE = 'projects_brainstorm_setting_inspired_by_change';
export const PROJECTS_BRAINSTORM_SETTING_THUMBNAIL_STYLE_CHANGE = 'projects_brainstorm_setting_thumbnail_style_change';
export const PROJECTS_CHANNEL_LINK_TO_YOUTUBE_CLICK = 'projects_channel_link_to_youtube_click';
export const PROJECTS_CLICK = 'projects_click';
export const PROJECTS_CONCEPT_SAVED = 'projects_concept_saved';
export const PROJECTS_COPY_CLICK_ON_SIDE = 'projects_copy_click_on_side';
export const PROJECTS_DELETE_CLICK_ON_SIDE = 'projects_delete_click_on_side';
export const PROJECTS_DELETE_PROJECT_CLICK = 'projects_delete_project_click';
export const PROJECTS_DOWNLOAD_CLICK_ON_SIDE = 'projects_download_click_on_side';
export const PROJECTS_DUPLICATE_PROJECT_CLICK = 'projects_duplicate_project_click';
export const PROJECTS_LESS_MORE_TOGGLE = 'projects_less_more_toggle';
export const PROJECTS_NEW_PROJECTS_CLICK_FROM_PROJECT_GRID = 'projects_new_projects_click_from_project_grid';
export const PROJECTS_NEW_PROJECTS_CLICK_FROM_TOP_RIGHT = 'projects_new_projects_click_from_top_right';
export const PROJECTS_PANEL_YOUTUBE_PREVIEW_SHOW = 'projects_panel_youtube_preview_show'; // here
export const PROJECTS_PANEL_YOUTUBE_PREVIEW_DIALOG_OPEN = 'projects_panel_youtube_preview_dialog_open';
export const PROJECTS_PANEL_YOUTUBE_PREVIEW_DIALOG_CLOSE = 'projects_panel_youtube_preview_dialog_close';
export const PROJECTS_PANEL_YOUTUBE_PREVIEW_THEME_CHANGE = 'projects_panel_youtube_preview_theme_change';
export const PROJECTS_PANEL_YOUTUBE_PREVIEW_DEVICE_TYPE_CHANGE = 'projects_panel_youtube_preview_device_type_change';
export const PROJECTS_PANEL_YOUTUBE_PREVIEW_SECTION_CHANGE = 'projects_panel_youtube_preview_section_change';
export const PROJECTS_PANEL_YOUTUBE_PREVIEW_SQUINT_ACTION = 'projects_panel_youtube_preview_squint_action';
export const PROJECTS_PANEL_YOUTUBE_PREVIEW_SHUFFLE_ACTION = 'projects_panel_youtube_preview_shuffle_action';
export const PROJECTS_PROJECTS_STATUS_FILTER_CHANGE = 'projects_projects_status_filter_change';
export const PROJECTS_PROJECTS_STATUS_FILTER_OPEN = 'projects_projects_status_filter_open';
export const PROJECTS_PROPERTIES_NOTEPAD_CHANGE = 'projects_properties_notepad_change';
export const PROJECTS_PROPERTIES_PROJECT_STATUS_CHANGE = 'projects_properties_project_status_change';
export const PROJECTS_PROPERTIES_PROJECT_STATUS_OPEN = 'projects_properties_project_status_open';
export const PROJECTS_PROPERTIES_PUBLISH_DATE_CHANGE = 'projects_properties_publish_date_change';
export const PROJECTS_PROPERTIES_PUBLISH_DATE_RESET = 'projects_properties_publish_date_reset';
export const PROJECTS_PROPERTIES_SHARED_TYPE_TOGGLE = 'projects_properties_shared_type_toggle';
export const PROJECTS_PROPERTIES_SPONSOR_CHANGE = 'projects_properties_sponsor_change';
export const PROJECTS_PROPERTIES_SPONSOR_RESET = 'projects_properties_sponsor_reset';
export const PROJECTS_PROPERTIES_TAG_ADD = 'projects_properties_tag_add';
export const PROJECTS_PROPERTIES_TAG_REMOVE = 'projects_properties_tag_remove';
export const PROJECTS_PROPERTIES_TARGET_DATE_CHANGE = 'projects_properties_target_date_change';
export const PROJECTS_PROPERTIES_TARGET_DATE_RESET = 'projects_properties_target_date_reset';
export const PROJECTS_PROPERTIES_TARGET_VIDEO_LENGTH_CHANGE = 'projects_properties_target_video_length_change';
export const PROJECTS_PROPERTIES_TARGET_VIDEO_LENGTH_RESET = 'projects_properties_target_video_length_reset';
export const PROJECTS_PROPERTIES_VIDEO_TYPE_CHANGE = 'projects_properties_video_type_change';
export const PROJECTS_PROPERTIES_VIDEO_TYPE_OPEN = 'projects_properties_video_type_open';
export const PROJECTS_PROPERTIES_YOUTUBE_URL_CHANGE = 'projects_properties_youtube_url_change';
export const PROJECTS_PROPERTIES_YOUTUBE_URL_RESET = 'projects_properties_youtube_url_reset';
export const PROJECTS_PUBLISHED_STATUS_TOGGLE = 'projects_published_status_toggle';
export const PROJECTS_REMOVE_FROM_SAVED_ITEM_CLICK = 'projects_remove_from_saved_item_click';
export const PROJECTS_SAVE_FOR_LATER_CLICK = 'projects_save_for_later_click';
export const PROJECTS_SEARCH_FILTER_CHANGE = 'projects_search_filter_change';
export const PROJECTS_SEARCH_FILTER_RESET = 'projects_search_filter_reset';
export const PROJECTS_SORT_CLICK = 'projects_sort_click';
export const PROJECTS_SORT_CREATED_DATE_NEWEST_TO_OLDEST_CLICK = 'projects_sort_created_date_newest_to_oldest_click';
export const PROJECTS_SORT_CREATED_DATE_OLDEST_TO_NEWEST_CLICK = 'projects_sort_created_date_oldest_to_newest_click';
export const PROJECTS_SORT_LAST_EDITED_NEWEST_TO_OLDEST_CLICK = 'projects_sort_last_edited_newest_to_oldest_click';
export const PROJECTS_SORT_LAST_EDITED_OLDEST_TO_NEWEST_CLICK = 'projects_sort_last_edited_oldest_to_newest_click';
export const PROJECTS_SORT_PUBLISH_DATE_NEWEST_TO_OLDEST_CLICK = 'projects_sort_publish_date_newest_to_oldest_click';
export const PROJECTS_SORT_PUBLISH_DATE_OLDEST_TO_NEWEST_CLICK = 'projects_sort_publish_date_oldest_to_newest_click';
export const PROJECTS_SORT_TITLE_A_Z_CLICK = 'projects_sort_title_A_Z_click';
export const PROJECTS_SORT_TITLE_Z_A_CLICK = 'projects_sort_title_Z_A_click';
export const PROJECTS_THUMBNAIL_SAVED = 'projects_thumbnail_saved';
export const PROJECTS_THUMBNAIL_UPLOAD_CLICK = 'projects_thumbnail_upload_click';
export const PROJECTS_THUMBNAIL_UPLOAD_SUCCESS = 'projects_thumbnail_upload_success';
export const PROJECTS_TITLE_SAVED = 'projects_title_saved';
export const PROJECTS_VIEW_PROJECT_CLICK = 'projects_view_project_click';
export const PROJECTS_IDEA_BANK_ADD_CLICK = 'projects_idea_bank_add_click';
export const PROJECTS_IDEA_BANK_BRAINSTORM_CLICK = 'projects_idea_bank_brainstorm_click';
