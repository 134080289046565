import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { isDynamicImportError } from '@studio/utils';
import { vars } from '@lib/theme';
import { Button, Flex, Heading, Text } from '@lib/ui';
import * as Styles from './error-boundary.css';

type Props = {
  error?: unknown;
  componentStack?: string;
  eventId?: string;
  resetError?: () => void;
};

export function ErrorBoundaryScreen(props: Props) {
  const { t } = useTranslation();
  const routerError = useRouteError() as unknown as Error;
  const passedError = props.error as Error;
  const error = passedError || routerError;

  // Handle when a new hash-versioned deployment wipes out old resources
  // (e.g navigating to /project 404s on project-3574ecee.js)
  if (routerError && isDynamicImportError(routerError.message)) {
    window.location.reload();
    return null;
  }

  return (
    <Flex
      flex="1"
      height="100%"
      width={vars.sizes.full}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="16px"
    >
      <Heading as="h1" size="36" weight="bold">
        {t('Something went wrong')}
      </Heading>
      <Flex
        flexDirection="column"
        gap="6px"
        justifyContent="center"
        alignItems="center"
      >
        <Text>
          {t(
            'We have encountered an unexpected error. Please refresh the page to try again, or logout below.'
          )}
        </Text>
        <Text>
          {t('If the issue persists, please contact customer support here:')}
        </Text>
        <Text weight="bold">
          <a
            href={import.meta.env.VITE_SUPPORT_URL}
            target="_blank"
            rel="noreferrer"
          >
            {import.meta.env.VITE_SUPPORT_URL}
          </a>
        </Text>
      </Flex>
      <Flex paddingTop="16px" gap="8px">
        <Button
          size="sm"
          variant="subtle"
          onClick={() => window.location.reload()}
        >
          Refresh Screen
        </Button>
        <Button
          size="sm"
          onClick={() => (window.location.href = '/auth/logout')}
        >
          Logout
        </Button>
      </Flex>
      {error ? (
        <div className={Styles.errorContainer}>
          <Text color="gray650">{t('Stuff for the nerds')}</Text>
          <pre className={Styles.stackTrace}>
            {error.stack}
            {props.componentStack ? (
              <p>
                <span>{t('Caused by:')}</span>
                {props.componentStack}
              </p>
            ) : null}
          </pre>
        </div>
      ) : null}
    </Flex>
  );
}
