import { type ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as DASHBOARD_EVENTS from '@studio/features/dashboard/constants/heap.constants';
import { trackEvent } from '@studio/lib/heap';
import { useModalStore } from '@studio/stores';
import { Avatar, Button } from '@lib/ui';
import { CHANNEL_SELECT_MODAL } from '../constants';
import { useChannelStore } from '../stores';
import * as Styles from './channel-select.css';

export const ChannelSelectTrigger = forwardRef(
  (props, ref: ForwardedRef<HTMLButtonElement>) => {
    const { getActiveChannel } = useChannelStore();
    const { toggleModal } = useModalStore();
    const { t } = useTranslation();

    function handleChannelSelectClick() {
      trackEvent(DASHBOARD_EVENTS.HOME_PROFILE_DROPDOWN_CLICK);
      toggleModal(CHANNEL_SELECT_MODAL);
    }

    return (
      <Button
        ref={ref}
        variant="subtle"
        size="xs"
        radii="pill"
        aria-label={t('User Menu')}
        onClick={handleChannelSelectClick}
        className={Styles.trigger}
      >
        <Avatar.Root name={getActiveChannel()?.name} size="32" pill>
          <Avatar.Image
            src={getActiveChannel()?.channelThumbnail || ''}
            alt={`${getActiveChannel()?.name} ${t('Channel thumbnail')}`}
          />
          <Avatar.Fallback />
        </Avatar.Root>
      </Button>
    );
  }
);
