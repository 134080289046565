import { forwardRef, PointerEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useInspirationStore } from '@studio/features/brainstorm/stores';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import * as OUTLIERS_EVENTS from '@studio/features/dashboard/constants/heap.constants';
import {
  useIdeationStore,
  useNewProjectId,
} from '@studio/features/projects/stores';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { useSavedItemsStore } from '@studio/features/saved-items/stores';
import { trackEvent } from '@studio/lib/heap';
import { useActiveOrganizationId, useModalStore } from '@studio/stores';
import { Video } from '@lib/gql/graphql';
import { EXIT_IDEATION, SavedItem, SavedItemsType } from '@lib/types';
import { IconButton, Tooltip } from '@lib/ui';
import * as Styles from './brainstorm-action.css';

type BrainstormActionProps = {
  className?: string;
  isHovered?: boolean;
  isInteractable?: boolean;
  showTooltip?: boolean;
  isTopic?: boolean;
  topic?: string;
  status?: string;
  element?: string;
};

type BrainstormActionWithDataProps = BrainstormActionProps &
  (
    | {
        data?: Video;
        type?: SavedItemsType.OUTLIERS;
      }
    | {
        data?: SavedItem;
        type?: Exclude<SavedItemsType, SavedItemsType.OUTLIERS>;
      }
  );

export const BrainstormAction = forwardRef<
  HTMLButtonElement,
  BrainstormActionWithDataProps
>(
  (
    {
      className,
      data,
      isHovered = false,
      isInteractable = true,
      showTooltip = false,
      isTopic = false,
      topic,
      type,
      status,
      element = 'title',
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const orgId = useActiveOrganizationId();
    const channelUcid = useActiveChannelUcid();
    const { toggleVisibility, visible } = useSavedItemsStore();
    const {
      setConcepts,
      setOutliers,
      setThumbnails,
      setTitles,
      setTopic,
      reset,
    } = useInspirationStore();
    const { inIdeation } = useIdeationStore();
    const { openModal } = useModalStore();
    const { getId } = useNewProjectId();

    const sendToBrainstorm = () => {
      reset();
      if (visible) {
        toggleVisibility();
      }
      if (!data && !isTopic) {
        return;
      }
      if (topic) {
        // TODO refactor to handle Topic setting more gracefully
        setTopic(topic);
      } else {
        switch (type) {
          case SavedItemsType.OUTLIERS:
            setOutliers([data] as Video[], true);
            break;
          case SavedItemsType.CONCEPT:
            setConcepts([data] as SavedItem[], true);
            break;
          case SavedItemsType.THUMBNAIL:
            setThumbnails([data] as SavedItem[], true);
            break;
          case SavedItemsType.TITLE:
            setTitles([data] as SavedItem[], true);
            break;
          default:
            console.warn(`Unhandled type: ${type}`);
        }
      }

      trackEvent(OUTLIERS_EVENTS.HOME_OUTLIER_BRAINSTORM_CLICK, {
        keyword: topic ?? '',
        page: location.pathname,
        data: JSON.stringify(data),
      });

      navigate({
        pathname: `/app/${orgId}/${channelUcid}/projects/new-project`,
        search: createSearchParams({
          [ProjectQueryParams.ELEMENT]: element,
          [ProjectQueryParams.BRAINSTORM]: 'true',
          [ProjectQueryParams.NEW_PROJECT_ID]: `${getId()}`,
          ...(status && { status }),
        }).toString(),
      });
    };

    const handleClick = (event: PointerEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (inIdeation) {
        openModal(EXIT_IDEATION, sendToBrainstorm);
        return;
      }
      sendToBrainstorm();
    };

    return (
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <IconButton
              icon={
                /**
                 * One off Icon here because our typical use of `currentColor` to set icon color won't work with gradients.
                 * TODO: Move this to our Icons directory if we need this elsewhere
                 */
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <defs>
                    <linearGradient
                      id="myGradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop offset="8.35%" stopColor="#8A8DF5" />
                      <stop offset="87.9%" stopColor="#FF7478" />
                    </linearGradient>
                  </defs>
                  <path
                    d="M18.0002 0L16.7399 2.75L14.0005 3.99976L16.7399 5.26012L18.0002 7.99952L19.25 5.26012L22 3.99976L19.25 2.75M7.99952 3.00048L5.5 8.50048L0 11L5.5 13.4995L7.99952 18.9995L10.499 13.4995L15.999 11L10.499 8.50048M17.9989 14.0005L16.7386 16.7399L13.9992 18.0002L16.7386 19.25L17.9989 22L19.2487 19.25L21.9987 18.0002L19.2487 16.7399"
                    fill="url(#myGradient)"
                  />
                </svg>
              }
              radii="lg"
              size="md"
              variant="subtle"
              onClick={handleClick}
              className={Styles.overrides}
              {...rest}
              ref={ref}
            />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content sideOffset={12}>{t('Brainstorm')}</Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  }
);
