import { useTranslation } from 'react-i18next';
import { Icons, Text, Flex } from '@lib/ui';
import * as Styles from './progress-bar.css';

type ProgressBarStep = {
  label: string;
  isCompleted?: boolean;
  isProcessing?: boolean;
  isLast?: boolean;
};

const ProgressStep = ({
  label,
  isCompleted,
  isProcessing,
  isLast,
}: ProgressBarStep) => (
  <Flex flexDirection="column" className={Styles.statusContainer}>
    <Flex className={Styles.circleContainer}>
      <Flex
        flexDirection="column"
        className={`${Styles.circle} ${isProcessing && 'processing'} ${
          isCompleted && 'completed'
        }`}
      >
        {isCompleted && <Icons.CheckmarkIcon aria-hidden />}
      </Flex>
    </Flex>
    <Text color="gray300" className={Styles.progressLabel}>
      {label}
    </Text>
    {!isLast ? (
      <div className={`${Styles.progressLine} ${isCompleted && 'completed'}`} />
    ) : null}
  </Flex>
);

export const ProgressBar = ({ currentStep }: { currentStep: number }) => {
  const { t } = useTranslation();

  const steps = ['Create account', 'Connect channel', 'Choose a plan'];

  return (
    <Flex className={Styles.progressBarContainer}>
      {steps.map((step, index) => (
        <ProgressStep
          key={step}
          label={t(step)}
          isCompleted={currentStep > index}
          isProcessing={currentStep === index}
          isLast={index === steps.length - 1}
        />
      ))}
    </Flex>
  );
};
